@use "sass:math";

@media print {
    .lni-c-rtw-letter {
        zoom: 62%;
    }

    .lni-c-rtw-letter__print-btn{
        display: none;
    }
}

.lni-c-rtw-letter {
    @include type-setting(md);

    font-family: 'Times New Roman', Times, serif;
    line-height: $line-height--tight;
    background-color: $background--content;
    padding: $space * 3;

    & .--row {
        display: inline-grid;
        grid-template-columns: auto auto;
    }

    ul {
        line-height: $line-height--base;
    }
}

.lni-c-rtw-letter__signature {
    @include type-setting(xs);

    & .--signature-line {
        padding-top: $space * 2;
        border-bottom: solid 1px $border--dark;
    }

    & .--row {
        grid-template-columns: 2fr 1fr;
    }
}

.lni-c-rtw-letter__contact {
    & .--row {
        grid-template-columns: 1fr auto;
    }
}

.lni-c-rtw-letter__print-btn {
    position: fixed;
    bottom: 0;
}

.lni-c-rtw-form {
    max-width: 33rem;

    & .--row {
        @include min-screen-width(md) {
            display: flex;
        }

        flex-wrap: flex;
    }

    & .--full-width-sm {
        @include min-screen-width(md) {
            width: initial;
        }

        width: 100%;
    }

    & .--inline-msg-mt0 {
        margin-top: 0;
    }

    //Exclude lni-input-date fields, .--inline-msg-mt0, and scheduled hours field
    & .lni-c-text-field:not([class*="--custom-width-"], .--inline-msg-mt0, .lni-c-rtw-form__scheduled-hours),
    .lni-c-select {
        margin-top: math.div($space, 2);
    }

    //Remove top margin from lni-input-date
    & [class*="lni-c-form-control--"],
    .lni-c-form-control {
        margin-top: 0;
    }

    & .lni-c-radio__description {
        @include type-setting(xxs);

        line-height: $line-height--tight;
        color: $text--lighter;
        padding-left: $radio-button-size + 4px;
    }
}

.lni-c-rtw-form__address {
    & .lni-c-text-field {
        margin-top: math.div($space, 2);
    }

    width: 33rem;
}

.lni-c-rtw-form__sup-name {
    @include min-screen-width(md) {
        margin-bottom: math.div($space, 2);
    }

    margin-bottom: 0;
}

.lni-c-rtw-form__sup-phone {
    @include min-screen-width(md) {
        margin-bottom: 0;
    }

    margin-bottom: math.div($space, 2);

    &.--has-error {
        margin-bottom: 0;
    }
}

.lni-c-rtw-form___report-date {
    width: 49rem;
}

.lni-c-rtw-form__report-time {
    @include min-screen-width(md) {
        margin-top: $space;
    }

    margin-top: 0;
}

.lni-c-rtw-form__pay-type {
    @include min-screen-width(md) {
        margin-bottom: 0;
    }

    margin-bottom: math.div($space, 2);
}

.lni-c-rtw-form__scheduled-hours {
    @include min-screen-width(md) {
        margin-top: math.div($space, 2);
    }

    margin-top: 0;
}